import {
  SET_CATEGORY,
  SET_START_UPS,
  TOGGLE_FAVOURITE,
  SAVE_SEARCH_CRITERIA,
  DELETE_CRITERIA,
  SET_FILTERS,
  SET_SEARCH_STARTUPS_LIST,
  SET_NUMBER_STARTUPS_LIST,
  SET_FAVOURITES,
  SET_TAGS,
  SET_TAGS_COLLECTION,
  EDIT_FILTERS,
  TOGGLE_REQUESTED_STARTUP,
  SET_SAVED_SEARCHES,
  WAIT_SEARCHES,
  SET_REQUEST_INFOS,
  GET_SIMILAR_STARTUP,
} from "./types"

//Actions
export const setStartups = (startupList) => ({
  type: SET_START_UPS,
  payload: startupList,
})

export const setSimilarStartups = (similarStartups) => ({
  type: GET_SIMILAR_STARTUP,
  payload: similarStartups,
})

export const toggleFavourite = (startup) => ({
  type: TOGGLE_FAVOURITE,
  payload: startup,
})

export const setCategory = (category) => ({
  type: SET_CATEGORY,
  payload: category,
})

export const saveSearchCriteria = (props) => ({
  type: SAVE_SEARCH_CRITERIA,
  payload: props,
})

export const deleteCriteria = (id) => ({
  type: DELETE_CRITERIA,
  payload: id,
})

export const setFilters = (filters) => ({
  type: SET_FILTERS,
  payload: filters,
})

export const setSearchStartupsList = (startups) => ({
  type: SET_SEARCH_STARTUPS_LIST,
  payload: startups,
})

export const setNumberStartupsList = (startups) => ({
  type: SET_NUMBER_STARTUPS_LIST,
  payload: startups,
})

export const setFavourites = (collection) => ({
  type: SET_FAVOURITES,
  payload: collection,
})

export const setTags = (collection) => ({
  type: SET_TAGS,
  payload: collection,
})

export const setTagsCollection = (collection) => ({
  type: SET_TAGS_COLLECTION,
  payload: collection,
})

export const setRequest = (collection) => ({
  type: SET_REQUEST_INFOS,
  payload: collection,
})

export const setSavedSearches = (collection) => ({
  type: SET_SAVED_SEARCHES,
  payload: collection,
})

export const editFilters = (filters = {}) => ({
  type: EDIT_FILTERS,
  payload: filters,
})

export const editWatching = (collection) => ({
  type: WAIT_SEARCHES,
  payload: collection,
})

export const toggleRequestedStartup = (startup) => ({
  type: TOGGLE_REQUESTED_STARTUP,
  payload: startup,
})
