// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CompanyList_CompanyList__wFmNL {\n  display: grid;\n  grid-gap: 4px; }\n\n.CompanyList_Header__3EDDN {\n  padding: 8px 16px;\n  text-align: left;\n  display: grid;\n  grid-gap: 5px;\n  grid-template-columns: 2fr 1fr 1fr 2fr 1fr 174px;\n  grid-auto-flow: column; }\n  .CompanyList_Header__3EDDN > p {\n    margin-bottom: 0px;\n    margin-top: 0px;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 20px;\n    letter-spacing: 0em;\n    text-align: left;\n    color: rgba(9, 30, 66, 0.48); }\n", "",{"version":3,"sources":["webpack://src/pages/Main/SearchStartups/CompanyList/CompanyList.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa,EAAA;;AAGf;EACE,iBAAiB;EACjB,gBAAgB;EAChB,aAAa;EACb,aAAa;EACb,gDAAgD;EAChD,sBAAsB,EAAA;EANxB;IAQI,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,4BAA4B,EAAA","sourcesContent":[".CompanyList {\n  display: grid;\n  grid-gap: 4px;\n}\n\n.Header {\n  padding: 8px 16px;\n  text-align: left;\n  display: grid;\n  grid-gap: 5px;\n  grid-template-columns: 2fr 1fr 1fr 2fr 1fr 174px;\n  grid-auto-flow: column;\n  > p {\n    margin-bottom: 0px;\n    margin-top: 0px;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 20px;\n    letter-spacing: 0em;\n    text-align: left;\n    color: rgba(9, 30, 66, 0.48);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CompanyList": "CompanyList_CompanyList__wFmNL",
	"Header": "CompanyList_Header__3EDDN"
};
export default ___CSS_LOADER_EXPORT___;
