// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dashboard_GridWrapper__IYjdc {\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-row-gap: 20px;\n  grid-column-gap: 20px;\n  margin-bottom: 24px;\n  grid-template-rows: 350px; }\n\n.Dashboard_Wrapper__1MDVm {\n  padding-top: 24px; }\n", "",{"version":3,"sources":["webpack://src/pages/Main/Dashboard/FirstSection/Dashboard.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,0BAA0B;EAC1B,kBAAkB;EAClB,qBAAqB;EACrB,mBAAmB;EACnB,yBAAyB,EAAA;;AAG3B;EACE,iBAAiB,EAAA","sourcesContent":[".GridWrapper {\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-row-gap: 20px;\n  grid-column-gap: 20px;\n  margin-bottom: 24px;\n  grid-template-rows: 350px;\n}\n\n.Wrapper {\n  padding-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GridWrapper": "Dashboard_GridWrapper__IYjdc",
	"Wrapper": "Dashboard_Wrapper__1MDVm"
};
export default ___CSS_LOADER_EXPORT___;
