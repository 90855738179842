import {
  setToken,
  setTokenRoleFrontFilters,
  setTokenRoleFrontSavedFavories,
  setTokenRoleFrontSavedSearch,
  setTokenRoleFrontSearch,
  setTokenRoleFrontList,
  setRoleUser,
} from "../role"

export const signIn = ({ email, password }) => async () => {
  const url = `${process.env.REACT_APP_API_URL}/api/v1/login`
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  })
    .then((res) => {
      if (res.status > 400) {
        throw "404"
      }
      return res.json()
    })
    .then((json) => {
      setToken(json.json.token)
      setRoleUser(json.json.role)
      setTokenRoleFrontFilters(json.json.user.role_front_filters)
      setTokenRoleFrontSavedFavories(json.json.user.role_front_saved_favories)
      setTokenRoleFrontSavedSearch(json.json.user.role_front_saved_search)
      setTokenRoleFrontSearch(json.json.user.role_front_search)
      setTokenRoleFrontList(json.json.user.role_front_list)
      return { email, avatarUrl: null }
    })
}

export const signOut = () => async () => {
  return true
}

//for deployment
