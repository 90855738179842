import React from "react"
import Title from "src/components/Typography/Title"
import FirstSection from "./FirstSection"
import SavedSearches from "./SavedSearches"
import { useSelector } from "react-redux"
import Button from "@material-ui/core/Button"

function Dashboard() {
  const logInState = useSelector((state) => state.view.loggedIn)

  return (
    <div
      style={{ margin: "auto", maxWidth: "1200px" }}
      data-test-id="dashboard-wrapper"
    >
      {logInState ? (
        <React.Fragment>
          <Title>Track & get notified about your favorite startups</Title>
          {localStorage.getItem("setTokenRoleFrontSavedSearch") === "true" ? (
            <SavedSearches />
          ) : (
            ""
          )}
          {localStorage.getItem("setTokenRoleFrontSavedFavories") === "true" ? (
            <FirstSection />
          ) : (
            ""
          )}
        </React.Fragment>
      ) : (
        <div style={{ paddingBottom: "40px" }}>
          <Title>Track & get notified about your favorite startups</Title>

          <img
            src="https://www.dropbox.com/s/xwcbs3ex63rfuk8/2106cal.png?raw=1&autoplay=1"
            alt="new"
            width="100%"
            style={{ padding: "10px" }}
          />

          <img
            src="https://www.dropbox.com/s/bekaszm9tu35vgf/fav9.png?raw=1&autoplay=1"
            alt="new"
            width="100%"
            style={{ padding: "10px" }}
          />

          <div style={{ display: "flex", textAlign: "center" }}>
            <Button
              variant="contained"
              style={{ position: "absolute", left: "35%", padding: "20px" }}
              width="40px"
              href={`mailto:sourcing@astorya.vc?subject=free & limited tracking&body=Hi, I'm interested in tracking the following 5 startups : `}
            >
              {"Track 5 for free"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ position: "absolute", left: "55%", padding: "20px" }}
              width="40px"
              href={`mailto:sourcing@astorya.vc?subject=unlimited & paid tracking&body=Hi, I'm interested in the unlimited tracking, could you share the pricing grid with me?`}
            >
              {"Get unlimited"}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Dashboard
