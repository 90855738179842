import React from "react"
import { useSelector } from "react-redux"
import { getFavourites } from "src/redux/selectors"

import classes from "./Dashboard.module.scss"
import CompanyList from "src/pages/Main/SearchStartups/CompanyList"
import { Typography } from "@material-ui/core"
import { FavouriteButton } from "src/components/common/IconButtons"

const EmptyPlaceholder = () => (
  <div
    style={{
      width: "100%",
      height: "300px",
      backgroundColor: "rgba(9, 30, 66, 0.02)",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <div
      style={{
        backgroundColor: "#FFFFFF",
        width: "90px",
        height: "90px",
        borderRadius: "50px",
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <FavouriteButton active={true} />
    </div>
    <Typography variant="h5">No favourite startups</Typography>
    <Typography variant="body2">Add by selecting the star button</Typography>
  </div>
)

export function Favourites() {
  const favourites = useSelector(getFavourites)

  const resultFavories = favourites.map((favorite) =>
    favorite.follower ? favorite.follower : favorite
  )
  return (
    <div className={classes.Wrapper}>
      <Typography variant="h5">Favorites</Typography>
      <CompanyList
        list={resultFavories}
        EmptyPlaceholder={EmptyPlaceholder}
        is_favorie={true}
      />
    </div>
  )
}
