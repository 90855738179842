import React, { useState, useRef } from "react"
import { useDispatch } from "react-redux"

import { Card } from "src/components/common/Card"
import { saveSearchCriteria } from "src/redux/actions"
import { PencilButton } from "src/components/common/IconButtons"

import { makeStyles } from "@material-ui/core/styles"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import { Button, Typography } from "@material-ui/core"
import TagSelectDropdown from "./TagSelectDropdown"
import { SearchApi, SavedSearchesApi } from "src/api"
import FilterCardList from "./FilterCardList"

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "432px",
    outline: "none",
    boxSizing: "border-box",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "8px",
    padding: "16px",
  },
}))

const formStates = {
  initial: "initial",
  pending: "pending",
  failed: "failed",
}

const useSearchApi = () => {
  const [formState, setState] = useState(formStates.initial)
  const dispatch = useDispatch()

  const search = async (filters, oldFilters) => {
    if (filters.category == oldFilters.category) {
      dispatch(
        saveSearchCriteria({
          props: { ...oldFilters, ...filters },
          index: filters.index,
        })
      )
      return
    }

    setState(formStates.pending)

    try {
      const results = await SearchApi.search({
        categories: [filters.category],
        search: "",
      })()

      dispatch(
        saveSearchCriteria({
          props: { ...filters, results: results.json.startups.slice(0, 9) },
          index: filters.index,
        })
      )
      setState(formStates.initial)

      return
    } catch (e) {
      setState(formStates.failed)
    }
  }

  return { formState, search }
}

function FilterCard({
  criteria = { title: "", category: "", results: [], id: "" },
  filtersIndex,
}) {
  const { formState, search } = useSearchApi()
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const mainTagRef = useRef(null)
  //const nameRef = useRef(null)

  async function onSaveClick() {
    const newValues = {
      title: mainTagRef.current.value,
      category: mainTagRef.current.value,
      index: filtersIndex,
    }
    // TODO temporary solution, delete this
    if (newValues.category.includes("All tags")) {
      newValues.category = newValues.category.split(" - ")[0]
    }

    try {
      await SavedSearchesApi.updateSavedSearches(criteria.id, newValues.category)
      await search(newValues, criteria)
      handleClose()
      // eslint-disable-next-line no-empty
    } catch {}
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const { title, results, category } = criteria

  return (
    <React.Fragment>
      <Card
        title={title || ""}
        titleExtra={<PencilButton onClick={handleOpen} />}
        height={250}
      >
        <FilterCardList
          title={title}
          list={results.slice(0, 10)}
          filters={criteria}
        />
      </Card>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography variant="h4">Edit category</Typography>
            <Typography variant="h6" style={{ marginTop: "16px" }}>
              Main tag
            </Typography>
            <TagSelectDropdown ref={mainTagRef} defaultValue={category} />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridColumnGap: "8px",
                width: "100%",
                marginTop: "38px",
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
                fullWidth
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={onSaveClick}
                fullWidth
              >
                {formState === formStates.pending
                  ? "Searching for startups"
                  : "Save to dashboard"}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  )
}

export default FilterCard
