import React from "react"
import { useSelector } from "react-redux"
import { getSavedSearches } from "src/redux/selectors"

import FilterCard from "./FilterCard"

import classes from "./SavedSearches.module.scss"

function SavedSearches() {
  const savedSearches = useSelector(getSavedSearches)
  return (
    <div>
      <p className={classes.Label}>Saved searches</p>
      <div className={classes.GridWrapper}>
        {savedSearches && savedSearches.length
          ? savedSearches.map((card, index) => (
              <FilterCard
                key={`Saved_criteria_${index}`}
                criteria={card}
                filtersIndex={index}
              />
            ))
          : null}
      </div>
    </div>
  )
}

export default SavedSearches
