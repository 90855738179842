import React, { useRef } from "react"
import { useSelector } from "react-redux"
import { getAllTagCollections } from "src/redux/selectors"

import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import Radio from "@material-ui/core/Radio"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import classes from "./SavedSearches.module.scss"

const Popper = ({ children }) => <div className={classes.Popper}>{children}</div>

const Paper = ({ children }) => <div className={classes.Paper}>{children}</div>

const getLabel = ({ value, parent }) =>
  value === parent ? `${value} - All tags` : value
// eslint-disable-next-line react/display-name
const TagSelectDropdown = React.forwardRef((props, ref) => {
  const tags = useSelector(getAllTagCollections)
  const defaultValue = useRef(tags.find((tag) => tag.value === props.defaultValue))
    .current
  return (
    <FormControl variant="outlined" fullWidth>
      <Autocomplete
        multiple={false}
        disableClearable
        groupBy={(option) => option.parent}
        renderTags={() => null}
        getOptionLabel={(option) => getLabel(option)}
        options={tags}
        defaultValue={defaultValue || null}
        PopperComponent={Popper}
        PaperComponent={Paper}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            inputRef={ref}
            className={classes.SmallerPadding}
            placeholder="Select or start typing"
          />
        )}
        renderOption={(option, { selected }) => (
          <FormControlLabel
            value={option.value}
            control={<Radio size="small" color="primary" />}
            label={getLabel(option)}
            checked={selected}
            style={{ paddingLeft: "0px" }}
          />
        )}
      />
    </FormControl>
  )
})

export default TagSelectDropdown
