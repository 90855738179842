import React from "react"
import classes from "./Kpi.module.scss"
import { Typography } from "@material-ui/core"
import Title from "src/components/Typography/Title"
import Carousel from "react-material-ui-carousel"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import image1 from "./image/InsurTechEUh12022_01.png"
import image2 from "./image/InsurTechEUh12022_02.png"
import image3 from "./image/InsurTechEUh12022_03.png"
import image4 from "./image/InsurTechEUh12022_04.png"
import image5 from "./image/InsurTechEUh12022_05.png"
import image6 from "./image/InsurTechEUh12022_06.png"
import image7 from "./image/InsurTechEUh12022_07.png"
import image8 from "./image/InsurTechEUh12022_08.png"
import image9 from "./image/InsurTechEUh12022_09.png"
import image10 from "./image/InsurTechEUh12022_10.png"
import image11 from "./image/InsurTechEUh12022_11.png"
import image12 from "./image/InsurTechEUh12022_12.png"
import image13 from "./image/InsurTechEUh12022_13.png"
import image14 from "./image/InsurTechEUh12022_14.png"
import image15 from "./image/InsurTechEUh12022_15.png"
import image16 from "./image/InsurTechEUh12022_16.png"
import image17 from "./image/InsurTechEUh12022_17.png"
import image18 from "./image/InsurTechEUh12022_18.png"
import image19 from "./image/InsurTechEUh12022_19.png"
import image20 from "./image/InsurTechEUh12022_20.png"
import image21 from "./image/InsurTechEUh12022_21.png"
import image22 from "./image/InsurTechEUh12022_22.png"
import image23 from "./image/InsurTechEUh12022_23.png"
import image24 from "./image/InsurTechEUh12022_24.png"
import image25 from "./image/InsurTechEUh12022_25.png"
import image26 from "./image/InsurTechEUh12022_26.png"
import image27 from "./image/InsurTechEUh12022_27.png"
import image28 from "./image/InsurTechEUh12022_28.png"

export function Kpi() {
  var items = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image19,
    image20,
    image21,
    image22,
    image23,
    image24,
    image25,
    image26,
    image27,
    image28,
  ]

  return (
    <div>
      <Title> Live data on the European InsurTech scene </Title>
      <div className={classes.Wrapper}>
        <Typography style={{ padding: "5px" }} variant="h5">
          Live Data
        </Typography>

        <div className={classes.Container} style={{ textAlign: "center" }}>
          <img
            src="https://www.dropbox.com/s/dhk7bpihwa4rnjl/astorya.io_kpilive1.png?raw=1&autoplay=1"
            alt="new"
            width="31%"
            style={{ padding: "5px" }}
          />
          <img
            src="https://www.dropbox.com/s/qj450tpi27mdkg1/astorya.io_kpilive2.png?raw=1&autoplay=1"
            alt="new"
            width="31%"
            style={{ padding: "5px" }}
          />
          <img
            src="https://www.dropbox.com/s/o83z1vvy6sindtg/astorya.io_kpilive3.png?raw=1&autoplay=1"
            alt="new"
            width="31%"
            style={{ padding: "5px" }}
          />
        </div>
        <div className={classes.Container} style={{ textAlign: "center" }}>
          <img
            src="https://www.dropbox.com/s/z6q1491hmdlytyp/astorya.io_kpilive4.png?raw=1&autoplay=1"
            alt="new"
            width="31%"
            style={{ padding: "5px" }}
          />
          <img
            src="https://www.dropbox.com/s/ved1ow9rpus4wb8/astorya.io_kpilive5.png?raw=1&autoplay=1"
            alt="new"
            width="31%"
            style={{ padding: "5px" }}
          />
          <img
            src="https://www.dropbox.com/s/pck26yqctve9ocn/astorya.io_kpilive6.png?raw=1&autoplay=1"
            alt="new"
            width="31%"
            style={{ padding: "5px" }}
          />
        </div>
      </div>
      <div className={classes.Wrapper}>
        <Typography variant="h5" style={{ padding: "5px" }}>
          {" "}
          Summary of the previous quarter:
        </Typography>

        <div className={classes.Container} style={{ textAlign: "center" }}>
          <Carousel
            autoPlay={false}
            navButtonsAlwaysVisible={true}
            NextIcon={<NavigateNextIcon />}
            PrevIcon={<NavigateBeforeIcon />}
          >
            {items.map((item, i) => (
              <img
                src={item}
                key={i}
                alt="new"
                width="95%"
                style={{ padding: "5px" }}
              />
            ))}
          </Carousel>

          <div>
            2021:
            <a href="https://www.linkedin.com/feed/update/urn:li:activity:6762981136722391040/">
              Jan
            </a>
            /
            <a href="https://www.linkedin.com/feed/update/urn:li:activity:6771703136777244672/">
              Feb
            </a>
            /
            <a href="https://www.linkedin.com/feed/update/urn:li:activity:6782920154520059904/">
              Mar
            </a>
            /
            <a href="https://www.linkedin.com/feed/update/urn:li:activity:6793792618665967616/">
              Apr
            </a>
            /
            <a href="https://www.linkedin.com/feed/update/urn:li:activity:6805026424433520640/">
              May
            </a>
            /
            <a href="https://www.linkedin.com/feed/update/urn:li:activity:6815897820697018368/">
              June
            </a>
            <br />
            2020:
            <a href="https://medium.com/@astoryaio/insurtech-europe-the-hottest-insurtech-deals-in-europe-2020-full-year-review-5ae4f0d52d8a">
              Full year
            </a>
            <br />
            2019:
            <a href="https://medium.com/@astorya_vc/market-the-european-insurtech-scene-2019-full-year-review-bf5287e1f665">
              Full year
            </a>
          </div>
        </div>
      </div>

      <div className={classes.Wrapper}>
        <Typography variant="h5" style={{ padding: "5px" }}>
          The most funded InsurTech startups in Europe:{" "}
        </Typography>

        <div className={classes.Container} style={{ textAlign: "center" }}>
          <img
            src="https://www.dropbox.com/s/v30gau77jt9kf2t/astorya.io_mostfundedinsurtech.png?raw=1&autoplay=1"
            alt="new"
            width="60%"
            style={{ padding: "5px" }}
          />

          <div>
            Top 3 :<a href="https://www.wefox.com/en-de">Wefox</a> /
            <a href="https://boughtbymany.com/">BoughtByMany </a> /
            <a href="https://www.shift-technology.com/"> Shift Technology </a>
          </div>
        </div>
      </div>
    </div>
  )
}
