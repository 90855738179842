import React from "react"
import classes from "./youtube.module.scss"
import Typography from "@material-ui/core/Typography"

export function YoutubeNotLogin() {
  return (
    <div className={classes.Wrapper}>
      <div style={{ display: "flex", textAlign: "-webkit-center" }}>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginLeft: "20%",
            marginRight: "20%",
            padding: "50px",
          }}
        >
          <Typography className={classes.Heading} variant="h5">
            {" "}
            How to find 580 SME fintechs?
          </Typography>
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/ykksJOjTIas"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  )
}
