import React from "react"
import { useSelector } from "react-redux"
import { Layout } from "src/components"

import classes from "./Main.module.scss"
import CircularProgress from "@material-ui/core/CircularProgress"

import { viewPages } from "src/redux/view/viewPages"
import Dashboard from "./Dashboard"
import Kpi from "./Kpi"
import SearchStartups from "./SearchStartups"
import { getApplicationState } from "src/redux/selectors"
import SignInPanel from "./SignIn"
import StartupModal from "./StartupModal"
import appStates from "src/redux/view/appStates"

function AppLoader() {
  return (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 100px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </div>
  )
}

const ContentRenderer = {
  [viewPages.DASHBOARD]: Dashboard,
  [viewPages.SEARCH]: SearchStartups,
  [viewPages.KPI]: Kpi,
  [viewPages.SIGN]: SignInPanel,
}

const getRenderer = ({ activePage, appState }) => {
  if (appState === appStates.error) return null

  if (appState === appStates.loading) return AppLoader

  // if (!isLoggedIn) return SignInPanel;

  //if (welcomePanelActive) return WelcomeModal;

  if (activePage && ContentRenderer[activePage]) return ContentRenderer[activePage]

  return null
}
// eslint-disable-next-line react/display-name
const Test = React.memo(function (props) {
  const Render = getRenderer(props)

  return (
    <div className={classes.Container}>
      {Render ? <Render /> : "Something wen't wrong. Please contact us..."}
    </div>
  )
})

function AppContent() {
  const appState = useSelector(getApplicationState)
  return <Test {...appState} />
}

function Main() {
  return (
    <Layout>
      <AppContent />
      <StartupModal />
    </Layout>
  )
}

export default Main
