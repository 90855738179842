// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Title_Title__2WCki {\n  text-align: center;\n  margin-top: 36px;\n  margin-bottom: 36px; }\n", "",{"version":3,"sources":["webpack://src/components/Typography/Title/Title.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB,EAAA","sourcesContent":[".Title {\n  text-align: center;\n  margin-top: 36px;\n  margin-bottom: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Title": "Title_Title__2WCki"
};
export default ___CSS_LOADER_EXPORT___;
