import React from "react"
import { useDispatch } from "react-redux"
import { Typography } from "@material-ui/core"
import { useSelector } from "react-redux"
import Link from "@material-ui/core/Link"
import { FavouriteButton } from "src/components/common/IconButtons"
import {
  toggleFavourite,
  editWatching,
  setCategory,
  editFilters,
} from "src/redux/actions"
import { FavouritesApi } from "src/api"
import AddIcon from "@material-ui/icons/Add"
import Fab from "@material-ui/core/Fab"
import classes from "./FilterCardList.module.scss"
import { viewPages } from "src/redux/view/viewPages"
import { setActivePage } from "src/redux/actions"

const Message = ({ text }) => (
  <div style={{ padding: "16px" }}>
    <Typography variant="body2">{text}</Typography>
  </div>
)

const FilterCardList = ({ list, filters, title }) => {
  if (!filters.category) {
    return <Message text="Select any category" />
  }

  if (!list || !list.length) {
    return <Message text="Empty list" />
  }

  const dispatch = useDispatch()
  const favourites = useSelector((state) => state.startups.favourites)

  function isFavorite(id) {
    return favourites.some((item) => item.id === id)
  }

  function onToggleFavourite(data) {
    isFavorite(data.id)
      ? FavouritesApi.deleteFavourite(data.id)
      : FavouritesApi.addFavourite(data.id)
    dispatch(toggleFavourite(data))
  }

  function onSelectTag(tag) {
    dispatch(editWatching("initial"))
    dispatch(setCategory(tag))
  }

  function editCurrentFilters(filters) {
    dispatch(editFilters(filters))
  }

  function resetFilters() {
    editCurrentFilters({
      page: 1,
      country: [],
      status: { active: true, merged: true, dead: true },
      categories: [],
      createdRange: [2000, 2021],
    })
  }

  return (
    <ul className={classes.GridWrapper}>
      {list.map((startup) => (
        <li key={startup.id}>
          <Typography variant="h4">
            <Link href={startup.linkedin} color="inherit" target="_blank">
              {startup.nom}
            </Link>
          </Typography>
          <Typography variant="body2">{startup.created}</Typography>
          <Typography variant="body2">{startup.pays}</Typography>
          <FavouriteButton
            active={isFavorite(startup.id)}
            onClick={() => onToggleFavourite(startup)}
          />
        </li>
      ))}
      <div style={{ textAlign: "center", opacity: "0.32", padding: "1em" }}>
        <Fab
          color="#091E42"
          aria-label="add"
          size="small"
          center
          variant="extended"
          className={classes.FavouriteButton}
        >
          <AddIcon
            onClick={() => {
              resetFilters()
              onSelectTag(title)
              setTimeout(function () {
                dispatch(setActivePage(viewPages.SEARCH))
              }, 1500)
            }}
          />
        </Fab>
      </div>
    </ul>
  )
}

export default FilterCardList
