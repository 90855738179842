import React, { useState, useRef } from "react"
import {
  Button,
  Typography,
  Modal,
  Input,
  FormControl,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import { Tag, Select, Slider } from "src/components/common"
import {
  EstabilishedMarks,
  getMarkLabel,
} from "src/components/common/Slider/sliderMarks"
import {
  editFilters,
  saveSearchCriteria,
  setCategory,
  editWatching,
} from "src/redux/startups/actions"
import { getSidebarSettings } from "src/redux/selectors"
import DetailsIcon from "@material-ui/icons/Details"
import classes from "./Sidebar.module.scss"

export default function Sidebar() {
  const dispatch = useDispatch()
  const [modalVisible, toggleModal] = useState(false)
  const [catTags, setCatTags] = useState([])
  const [subcatTags, setSubCatTags] = useState([])
  const [enabledClick, setEnabledClick] = useState([])

  const { mainTags, countries, technology, customerSegment } = useSelector(
    getSidebarSettings
  )
  const inputRef = useRef()

  const filters = useSelector((state) => state.startups.filters)
  const tagsIncoming = useSelector((state) => state.startups.tags)

  let insrttagArray = []
  mainTags &&
    mainTags.map((word) => {
      localStorage.getItem("setRoleUser") &&
      localStorage.getItem("setRoleUser").includes(word.parent)
        ? insrttagArray && insrttagArray.push(word.parent)
        : ""
      word.subTags.map((item) =>
        item.map((it) =>
          localStorage.getItem("setRoleUser") &&
          localStorage.getItem("setRoleUser").includes(it)
            ? insrttagArray.push(it)
            : ""
        )
      )
    })
  tagsIncoming &&
    tagsIncoming.mainTags &&
    tagsIncoming.mainTags.map((parentTag) => {
      parentTag.subTags.map((subTag) => {
        if (insrttagArray.includes(subTag.name)) {
          const index = insrttagArray.indexOf(subTag.name)
          insrttagArray[index] = [subTag.name, subTag.color]
        }
      })
    })

  let insrttagArrayTech = []
  technology &&
    technology.filter((word) => {
      localStorage.getItem("setRoleUser") &&
      localStorage.getItem("setRoleUser").includes(word)
        ? insrttagArrayTech && insrttagArrayTech.push(word)
        : ""
    })

  tagsIncoming &&
    tagsIncoming.mainTags &&
    tagsIncoming.mainTags.map((parentTag) => {
      parentTag.subTags.map((subTag) => {
        if (insrttagArrayTech.includes(subTag.name)) {
          const index = insrttagArrayTech.indexOf(subTag.name)
          insrttagArrayTech[index] = [subTag.name, subTag.color]
        }
      })
    })

  let insrttagArrayCus = []
  customerSegment &&
    customerSegment.filter((word) => {
      localStorage.getItem("setRoleUser") &&
      localStorage.getItem("setRoleUser").includes(word)
        ? insrttagArrayCus && insrttagArrayCus.push(word)
        : ""
    })

  tagsIncoming &&
    tagsIncoming.mainTags &&
    tagsIncoming.mainTags.map((parentTag) => {
      parentTag.subTags.map((subTag) => {
        if (insrttagArrayCus.includes(subTag.name)) {
          const index = insrttagArrayCus.indexOf(subTag.name)
          insrttagArrayCus[index] = [subTag.name, subTag.color]
        }
      })
    })

  const {
    categories,
    createdRange,
    country,
    //status = { active: true, merged: true, dead: true },
    analyse = false,
  } = filters

  function editCurrentFilters(filters) {
    dispatch(editWatching("initial"))
    dispatch(editFilters(filters))
  }

  function onChangeCreatedRange(value) {
    editCurrentFilters({ createdRange: value })
  }
  // function onChangeFunraisingRange(value) {
  //   editCurrentFilters({ fundraisingRange: value })
  // }

  function onSelectTag(tag) {
    dispatch(editWatching("initial"))
    dispatch(setCategory(tag))
  }

  function onCountryChange(event, newInputValue) {
    editCurrentFilters({ country: newInputValue })
  }

  function isActive(tag, number) {
    //dispatch(setCategory(tag));
    return number === 0
      ? categories.includes(tag)
      : number === 1
      ? catTags.includes(tag) || categories.includes(tag)
      : number === 2
      ? subcatTags.includes(tag) || categories.includes(tag)
      : ""
  }

  // function openModal() {
  //   toggleModal(true)
  // }

  function saveToDashboard() {
    dispatch(saveSearchCriteria(inputRef.current.input.value))
    toggleModal(false)
  }

  function resetFilters() {
    document.getElementById("TextFieldValueSearch").value = ""
    setCatTags([])
    setEnabledClick([])
    setSubCatTags([])
    editCurrentFilters({
      country: [],
      analysis: false,
      status: { active: true, merged: true, dead: true },
      categories: [],
      createdRange: [2000, 2021],
      search: "",
      page: 1,
    })
  }

  function handleCheckboxChangeAstoryaAnalysis(value) {
    if (value === true) {
      editCurrentFilters({ analyse: false })
    } else {
      editCurrentFilters({ analyse: true })
    }
  }

  // function handleCheckboxChangeEmployee(type) {
  //   editCurrentFilters({ status: { ...status, [type]: !status[type] } })
  // }

  async function handletagsrefresh(tags) {
    const parents = await catTags.indexOf(tags.parent)
    const resultSlice = await catTags[parents]
    return setCatTags(catTags.filter((word) => word !== resultSlice))
  }

  function arraySplice(array, number) {
    const index = array.indexOf(number)
    array.splice(index, 1)
    return array
  }

  const options = countries && [...countries.map(({ name }) => name)]

  let searchUrl = window.location.search
  let params = new URLSearchParams(searchUrl)
  let tokenParams = params.get("tokenShow")
  const colorWeight = ["blue", "purple", "pink", "green", "orange", "red"]

  return (
    <div className={classes.Container}>
      <div
        style={{
          display: "inline-flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          width: "100%",
        }}
      >
        <Typography variant="h5"> Advanced criteria </Typography>
        <span
          onClick={resetFilters}
          style={{
            color: "#F51047",
            cursor: "pointer",
            fontSize: "12px",
            fontWeight: 800,
            padding: "5px",
          }}
        >
          Clear all
        </span>
      </div>
      <section className={classes.Section}>
        <Typography variant="h6"> Tags </Typography>

        <div>
          {localStorage.getItem("setRoleUser") === "" || !!tokenParams
            ? tagsIncoming.mainTags &&
              tagsIncoming.mainTags.map((tags) => (
                <div key={`mainTag${tags.parent}`} className={classes.TagsWrapper}>
                  {tags.parent !== "CustomerSegment" &&
                  tags.parent !== "BusinessModels" ? (
                    <React.Fragment>
                      <Tag
                        color="blue"
                        active={isActive(tags.parent, 1)}
                        parent={true}
                        onClick={() =>
                          enabledClick.includes(tags.parent)
                            ? ""
                            : catTags.includes(tags.parent)
                            ? catTags.length === 1
                              ? setCatTags([])
                              : handletagsrefresh(tags)
                            : setCatTags([...catTags, tags.parent])
                        }
                        block={true}
                      >
                        {tags.parent} <DetailsIcon style={{ fontSize: "8px" }} />
                      </Tag>
                      {catTags &&
                        catTags.includes(tags.parent) &&
                        // ||
                        // tags.subTags.some((subCollection) =>
                        // subCollection && subCollection.includes(catTags[0])
                        // ))
                        tags.subTags
                          .sort(function (a, b) {
                            if (a && b) {
                              if (
                                colorWeight.findIndex((item) => item === a.color) <
                                colorWeight.findIndex((item) => item === b.color)
                              )
                                return -1
                              if (
                                colorWeight.findIndex((item) => item === a.color) >
                                colorWeight.findIndex((item) => item === b.color)
                              )
                                return 1
                              return 0
                            } else {
                              return -1
                            }
                          })
                          .map((col) => (
                            <Tag
                              key={`mainSubTag${col.id}`}
                              color={col.color}
                              active={isActive(col.name, 2)}
                              onClick={() => {
                                onSelectTag(col.name)
                                setEnabledClick(
                                  isActive(col.name, 2)
                                    ? enabledClick.length === 1
                                      ? []
                                      : arraySplice(enabledClick, tags.parent)
                                    : [...enabledClick, tags.parent]
                                )
                                return subcatTags.includes(col.name)
                                  ? subcatTags.length === 1
                                    ? setSubCatTags([])
                                    : setSubCatTags(
                                        arraySplice(subcatTags, col.name)
                                      )
                                  : setSubCatTags([...subcatTags, col.name])
                              }}
                            >
                              {col.name}
                            </Tag>
                          ))}
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </div>
              ))
            : insrttagArray.map((tags) => (
                <div key={`mainTag${tags}`} className={classes.TagsWrapper}>
                  <Tag
                    color={tags[1]}
                    active={isActive(tags, 2)}
                    parent={true}
                    onClick={() => onSelectTag(tags)}
                    block={true}
                  >
                    {tags[0]}
                  </Tag>
                </div>
              ))}
        </div>
      </section>

      <section className={classes.Section}>
        <Typography variant="h6">Customer segment</Typography>
        <div className={classes.TagsWrapper}>
          {localStorage.getItem("setRoleUser") === "" || !!tokenParams
            ? tagsIncoming.mainTags &&
              tagsIncoming.mainTags.map((tag) => {
                return tag.parent === "CustomerSegment" ? (
                  tag.subTags.map((tag) => (
                    <Tag
                      key={`customerTag${tag.name}`}
                      active={isActive(tag.name, 1)}
                      onClick={() => onSelectTag(tag.name)}
                      color={tag.color}
                      //color={getColor(tag.color)}
                    >
                      {tag.name}
                    </Tag>
                  ))
                ) : (
                  <div></div>
                )
              })
            : insrttagArrayCus.map((tag) => (
                <Tag
                  key={`customerTag${tag}`}
                  active={isActive(tag, 1)}
                  onClick={() => onSelectTag(tag)}
                  color={tag[1]}
                >
                  {tag[0]}
                </Tag>
              ))}
        </div>
      </section>

      <section className={classes.Section}>
        <Typography variant="h6">Business Models</Typography>
        <div className={classes.TagsWrapper}>
          {localStorage.getItem("setRoleUser") === "" || !!tokenParams
            ? tagsIncoming.mainTags &&
              tagsIncoming.mainTags.map((tag) => {
                return tag.parent === "BusinessModels" ? (
                  tag.subTags.map((tag) => (
                    <Tag
                      key={`techTag${tag.name}`}
                      active={isActive(tag.name, 1)}
                      onClick={() => onSelectTag(tag.name)}
                      color={tag.color}
                      //color="orange"
                    >
                      {tag.name}
                    </Tag>
                  ))
                ) : (
                  <div></div>
                )
              })
            : insrttagArrayTech.map((tag) => (
                <Tag
                  key={`techTag${tag.name}`}
                  active={isActive(tag, 1)}
                  onClick={() => onSelectTag(tag)}
                  color={tag[1]}
                >
                  {tag[0]}
                </Tag>
              ))}
        </div>
      </section>

      <section className={classes.Section}>
        <Typography variant="h6">Country</Typography>
        <div className={classes.TagsWrapper}>
          {country.map((tag) => (
            <Tag
              key={`techTag${tag}`}
              active={isActive(tag, 0)}
              color="grey"
              style={{ margin: "5px" }}
            >
              {tag}
            </Tag>
          ))}
        </div>
        <FormControl variant="outlined" style={{ marginTop: "10px" }} fullWidth>
          <Select
            multiple={true}
            onChange={onCountryChange}
            options={options}
            value={country}
          />
        </FormControl>
      </section>
      <section className={classes.Section}>
        <Typography variant="h6">Status</Typography>
        <section className={classes.Section}>
          <FormControlLabel
            control={
              <Checkbox
                id="analyse"
                name="analyse"
                checked={analyse}
                onChange={() => handleCheckboxChangeAstoryaAnalysis(analyse)}
                color="primary"
              />
            }
            label="astorya.io analysis"
          ></FormControlLabel>
        </section>
      </section>

      <Slider
        title="Established"
        min={EstabilishedMarks[0].value}
        max={EstabilishedMarks[EstabilishedMarks.length - 1].value}
        marks={EstabilishedMarks}
        valueLabelFormat={(value) => getMarkLabel(value, EstabilishedMarks)}
        onChange={onChangeCreatedRange}
        defaultValue={createdRange}
      />

      {/* <Button
        variant="contained"
        color="primary"
        className={classes.Button}
        onClick={openModal}
        fullWidth
      >
        Save criteria to dashboard
      </Button> */}
      <Modal
        open={modalVisible}
        // onOk={() => saveToDashboard()}
        // onCancel={() => toggleModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div>
          <p>
            Results from this search with selected criteria will be always available
            in dashboard
          </p>
          <Input ref={inputRef} placeholder="Enter name" defaultValue="" />
          <Button key="cancel" variant="contained" danger style={{ width: "49%" }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={saveToDashboard}
            style={{ width: "49%" }}
          >
            Save to dashboard
          </Button>
        </div>
      </Modal>
    </div>
  )
}
