import React from "react"
import PropTypes from "prop-types"
import CardHeader from "@material-ui/core/CardHeader"
import MuiCard from "@material-ui/core/Card"
import { Typography } from "@material-ui/core"

export function Card({ title, titleExtra, children, height = 0 }) {
  return (
    <MuiCard
      style={{
        height: height ? `${height}px` : "auto",
        borderRadius: "8px",
        display: "grid",
        gridTemplateColumns: "100%",
        gridTemplateRows: "max-content 1fr",
      }}
    >
      <CardHeader
        style={{
          borderBottom: "1px solid rgba(9, 30, 66, 0.08)",
        }}
        title={
          <div
            style={{
              display: "inline-flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              style={{ display: "inline-block", cursor: "pointer" }}
              variant="h5"
            >
              {title}
            </Typography>
            <div>{titleExtra}</div>
          </div>
        }
      ></CardHeader>
      {children}
    </MuiCard>
  )
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  titleExtra: PropTypes.element,
  bodyHeight: PropTypes.number,
}
