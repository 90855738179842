import React, { useRef, useState, useEffect } from "react"

import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import { useSnackbar } from "notistack"

import { ShareStartupApi } from "src/api"

import * as yup from "yup"

let emailSchema = yup
  .string()
  .email("Wrong email schema")
  .required("Email is required")

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "432px",
    outline: "none",
    boxSizing: "border-box",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "8px",
    padding: "16px",
  },
}))

const formStatus = {
  initial: "initial",
  sending: "sending",
  error: "error",
}

function ShareModal({ startup, open, handleClose, onCloseEnd }) {
  const emailRef = useRef(null)
  const [inputError, setError] = useState("")
  const [status, setStatus] = useState(formStatus.initial)
  const modalClasses = useStyles()

  const { enqueueSnackbar } = useSnackbar()

  function resetInputError() {
    setError("")
  }

  useEffect(() => {
    resetInputError()
  }, [startup])

  function showSuccess() {
    enqueueSnackbar("Startup shared successfully.", { variant: "success" })
  }

  function showError() {
    enqueueSnackbar("We couldn't share this startup, please try again later.", {
      variant: "error",
    })
  }

  async function triggerSendEmail(email) {
    setStatus(formStatus.sending)
    try {
      await ShareStartupApi.startupShare(startup.id, email)
      setStatus(formStatus.initial)
      showSuccess()
    } catch (err) {
      setStatus(formStatus.error)
      showError()
    }
    handleClose()
  }

  async function onSendClick() {
    const inputValue = emailRef.current.value

    try {
      await emailSchema.validate(inputValue)
      resetInputError()
      triggerSendEmail(inputValue)
    } catch (err) {
      if (err.errors) {
        err.errors.length && setError(err.errors[0])
      }
    }
  }

  const buttonsDisabled = status !== formStatus.initial
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={modalClasses.modal}
      open={open}
      BackdropComponent={Backdrop}
      disableBackdropClick
      BackdropProps={{
        timeout: 350,
      }}
    >
      <Fade in={open} onExited={onCloseEnd}>
        <div className={modalClasses.paper} data-test-id="share-modal-content">
          <Typography variant="h4">Share startup</Typography>
          <div
            style={{
              marginTop: "20px",
              minHeight: "100px",
              display: "grid",
              gridRowGap: "14px",
              gridTemplateRows: "max-content",
              alignItems: "flex-end",
            }}
          >
            <Typography variant="body2">
              Send{" "}
              <span style={{ fontWeight: 700 }}>{startup?.name || "startup"}</span>{" "}
              to:
            </Typography>
            <TextField
              variant="outlined"
              inputRef={emailRef}
              error={!!inputError}
              helperText={inputError}
              placeholder="Email"
              fullWidth
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridColumnGap: "8px",
                width: "100%",
                marginTop: "18px",
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
                fullWidth
                disabled={buttonsDisabled}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={onSendClick}
                disabled={buttonsDisabled}
                fullWidth
                href={`mailto:${
                  emailRef && emailRef.current && emailRef.current.value
                }?subject=InsurTech startup suggested by your colleague&body=Hi,%0D Using astorya.io automated scouting tool, ${
                  emailRef && emailRef.current && emailRef.current.value
                } has spotted  "${startup && startup.nom}( ${
                  startup &&
                  (startup.linkedin !== ""
                    ? startup.linkedin
                    : startup.site_web !== ""
                    ? startup.site_web
                    : startup.other_website)
                })" and thought you might be interested in that startup.%0D Enjoy that discovery,%0D The astoryaIO team`}
              >
                {buttonsDisabled ? "Sending..." : "Send"}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

export default ShareModal
