import React, { useCallback, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"

import { editFilters, editWatching } from "src/redux/startups/actions"
import CompanyCard from "./Card"
import classes from "./CompanyList.module.scss"
import ShareModal from "./ShareModal"
import Pagination from "@material-ui/lab/Pagination"
import CircularProgress from "@material-ui/core/CircularProgress"

// eslint-disable-next-line react/display-name
export const List = React.memo(({ filteredStartupList }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [startup, setStartup] = useState(null)

  const handleClose = () => {
    setOpen(false)
  }

  const onCloseTransitionEnd = () => {
    setStartup(null)
  }

  const openModal = useCallback((startupId) => {
    setStartup(startupId)
    setOpen(true)
  }, [])

  useEffect(() => {
    if (
      startup &&
      !filteredStartupList.some((company) => company.id === startup.id)
    ) {
      handleClose()
    }
  }, [filteredStartupList])
  dispatch(editWatching(""))
  return (
    <div className={classes.CompanyList} data-test-id="startups-list">
      {filteredStartupList.map((startup) => (
        <CompanyCard
          key={startup.id}
          onShareClick={openModal}
          data={startup}
          displayMoreButton={true}
        />
      ))}
      <ShareModal
        open={open}
        startup={startup}
        handleClose={handleClose}
        onCloseEnd={onCloseTransitionEnd}
      />
    </div>
  )
})
// eslint-disable-next-line react/display-name
const Placeholder = React.memo(({ EmptyPlaceholder }) => {
  const dispatch = useDispatch()
  dispatch(editWatching(""))
  return EmptyPlaceholder ? <EmptyPlaceholder /> : "No data"
})

export default function CompanyList({ list, EmptyPlaceholder, is_favorie }) {
  const numberStartups = useSelector((state) => state.startups.numberStartups)
  const numberFavories = useSelector((state) => state.startups.favories)
  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = React.useState(0)
  const dispatch = useDispatch()

  const PER_PAGE = 50
  //const offset = page * PER_PAGE
  const currentPageData = list

  const pageCount = is_favorie
    ? Math.ceil(numberFavories / PER_PAGE)
    : Math.ceil(numberStartups / PER_PAGE)
  const waitSearch = useSelector((state) => state.startups.waitSearch)

  function editCurrentFilters(filters) {
    dispatch(editFilters(filters))
  }

  const handleChange = (event, value) => {
    dispatch(editWatching("initial"))
    setPage(value)
    editCurrentFilters({ page: value })
    window.scrollTo(0, 0)
  }

  const filters = useSelector((state) => state.startups.filters)

  return (
    <div className={classes.Container}>
      {waitSearch === "initial" ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : null}
      <div className={classes.Header}>
        <p>Company name</p>
        <p>Country</p>
        <p>Est.</p>
        <p>Tags</p>
        <p>Links</p>
        <span></span>
      </div>
      {list.length ? (
        <List filteredStartupList={currentPageData} />
      ) : (
        <Placeholder EmptyPlaceholder={EmptyPlaceholder} />
      )}
      <div className={classes.root}>
        {!is_favorie ? (
          <Pagination
            count={pageCount}
            page={filters.page}
            onChange={handleChange}
            color="primary"
          />
        ) : (
          ""
        )}
      </div>
    </div>
  )
}

/* */
