//@ts-check
import {
  SET_START_UPS,
  TOGGLE_FAVOURITE,
  SET_CATEGORY,
  SAVE_SEARCH_CRITERIA,
  DELETE_CRITERIA,
  SET_FILTERS,
  SET_SEARCH_STARTUPS_LIST,
  SET_NUMBER_STARTUPS_LIST,
  SET_FAVOURITES,
  SET_TAGS,
  SET_TAGS_COLLECTION,
  EDIT_FILTERS,
  TOGGLE_REQUESTED_STARTUP,
  SET_SAVED_SEARCHES,
  WAIT_SEARCHES,
  SET_REQUEST_INFOS,
  GET_SIMILAR_STARTUP,
} from "./types"

import { initState } from "../initState"
import produce from "immer"
const { startups: startupsInitState } = initState

export default function reducer(state = startupsInitState, action) {
  const { type, payload } = action

  const cases = {
    [SET_START_UPS]: () => ({
      ...state,
      filteredStartupList: payload,
    }),

    [TOGGLE_FAVOURITE]: () => ({
      ...state,
      favourites: toggleStartupInList(state.favourites, payload),
    }),

    [TOGGLE_REQUESTED_STARTUP]: () => ({
      ...state,
      requestedStartups: toggleStartupInList(state.requestedStartups, payload),
    }),

    [SET_CATEGORY]: () => {
      const newFilters = {
        ...state.filters,
        categories:
          payload === "" ? [] : toggleTag(state.filters.categories, payload),
      }

      return {
        ...state,
        filters: newFilters,
      }
    },

    [EDIT_FILTERS]: () => {
      const newFilters = { ...state.filters, ...payload }
      return { ...state, filters: { ...newFilters } }
    },

    [SET_FILTERS]: () => {
      return {
        ...state,
        filters: payload,
      }
    },

    [SAVE_SEARCH_CRITERIA]: () => {
      return produce(state, (draft) => {
        draft.savedSearches[payload.index] = payload.props
      })
    },

    [DELETE_CRITERIA]: () => {
      return {
        ...state,
        savedSearches: state.savedSearches.filter((search) => search.id !== payload),
      }
    },

    [SET_SEARCH_STARTUPS_LIST]: () => {
      return {
        ...state,
        filteredStartupList: payload,
      }
    },
    [SET_NUMBER_STARTUPS_LIST]: () => {
      return {
        ...state,
        numberStartups: payload,
      }
    },
    [SET_SAVED_SEARCHES]: () => {
      return {
        ...state,
        savedSearches: payload,
      }
    },
    [WAIT_SEARCHES]: () => {
      return {
        ...state,
        waitSearch: payload,
      }
    },
    [SET_REQUEST_INFOS]: () => {
      return {
        ...state,
        requestInfos: payload,
      }
    },
    [GET_SIMILAR_STARTUP]: () => {
      return {
        ...state,
        similarStartups: payload,
      }
    },
    [SET_TAGS]: () => {
      return {
        ...state,
        tags: payload,
      }
    },
    [SET_TAGS_COLLECTION]: () => {
      return {
        ...state,
        tagsCollections: payload,
      }
    },
    [SET_FAVOURITES]: () => {
      return {
        ...state,
        favourites: payload,
      }
    },
  }

  return cases[type] ? cases[type]() : state
}

function toggleStartupInList(list, startup) {
  const clone = { ...startup }
  if (list.find((fav) => fav.id === startup.id)) {
    return list.filter((fav) => fav.id !== startup.id)
  }

  return [clone, ...list]
}

function toggleTag(tagsList, tag) {
  const clone = [...tagsList]
  const index = tagsList.findIndex((item) => item === tag)

  if (index === -1) clone.push(tag)
  else clone.splice(index, 1)

  return clone
}
