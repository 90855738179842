import React from "react"
import { useEffect, useState } from "react"

import { useSelector } from "react-redux"
import Title from "src/components/Typography/Title"
import Search from "./Search"
import Sidebar from "./Sidebar"
import { YoutubeNotLogin } from "./youtubeNotLogin"
import CompanyList from "./CompanyList"

import classes from "./SearchStartups.module.scss"
import Modal from "@material-ui/core/Modal"
import HighlightedText from "src/components/Typography/HighlightedText"
import Typography from "@material-ui/core/Typography"
import Carousel from "react-material-ui-carousel"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"

function StartupsList() {
  const filteredStartupList = useSelector((state) => {
    return state.startups.filteredStartupList
  })

  let search = window.location.search
  let params = new URLSearchParams(search)
  let tokenParams = params.get("tokenShow")
  // eslint-disable-next-line no-extra-boolean-cast
  const displayStartups = !!tokenParams
    ? filteredStartupList.slice(0, 1)
    : filteredStartupList

  return <CompanyList list={displayStartups} />
}

function SearchStartups() {
  const logInState = useSelector((state) => state.view.loggedIn)
  const startups = useSelector((state) => state.startups.numberStartups)
  const [count, setCount] = useState(0)

  let search = window.location.search
  let params = new URLSearchParams(search)
  let tokenParams = params.get("tokenShow")

  useEffect(async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/nbstartups`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    const data = await response.json()
    setCount(data.json.nbStartups)
  }, [])

  const OneStartupList = useSelector((state) => {
    return state.startups.filteredStartupList
  })

  var items =
    OneStartupList &&
    OneStartupList.sort(function (a, b) {
      return a.page_number - b.page_number
    }).map((link) => {
      return {
        name: link.name,
      }
    })

  return (
    <div data-test-id="search-startups-wrapper">
      <Title>
        {logInState ? (
          startups > 3490 ? (
            <div>
              There are <HighlightedText> {startups} </HighlightedText> Partners in
              our database.
            </div>
          ) : (
            <div>
              We found <HighlightedText> {startups} </HighlightedText> Partners.
            </div>
          )
        ) : (
          <div>
            There are <HighlightedText> {count} </HighlightedText> Partners in our
            database
          </div>
        )}
      </Title>
      {logInState ? (
        <div className={classes.Wrapper}>
          {localStorage.getItem("setTokenRoleFrontFilters") === "true" ? (
            <Sidebar />
          ) : (
            ""
          )}
          {localStorage.getItem("setTokenRoleFrontSearch") === "true" ? (
            <Search />
          ) : (
            ""
          )}
          {localStorage.getItem("setTokenRoleFrontList") === "true" ? (
            <StartupsList />
          ) : (
            ""
          )}
        </div>
      ) : tokenParams && tokenParams.length === 20 ? (
        <div className={classes.Wrapper}>
          <Sidebar />
          <Search />
          <div>
            {OneStartupList &&
            OneStartupList[0] &&
            OneStartupList[0].demo_video_link ? (
              <React.Fragment>
                <StartupsList />
                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    paddingBottom: "10px",
                  }}
                >
                  <div
                    style={{ width: "100%", textAlign: "center", padding: "20px" }}
                  >
                    <Typography
                      className={classes.Heading}
                      variant="h5"
                      style={{ marginBottom: "15px" }}
                    >
                      Product Demo
                    </Typography>
                    <iframe
                      width="95%"
                      height="315"
                      src={OneStartupList[0].demo_video_link}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}

            {items.length > 0 && items[0].name ? (
              <div style={{ textAlign: "center" }}>
                <Typography
                  className={classes.Heading}
                  variant="h5"
                  style={{ marginBottom: "15px" }}
                >
                  Product Description
                </Typography>
                <Carousel
                  autoPlay={false}
                  navButtonsAlwaysVisible={true}
                  NextIcon={<NavigateNextIcon />}
                  PrevIcon={<NavigateBeforeIcon />}
                >
                  {items.map((item, i) => (
                    <img
                      src={item.name}
                      key={i}
                      alt="new"
                      width="95%"
                      style={{ padding: "5px" }}
                    />
                  ))}
                </Carousel>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <YoutubeNotLogin />
      )}
      <Modal></Modal>
    </div>
  )
}

export default SearchStartups
